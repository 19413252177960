@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  flex-flow: column;
  min-height: 100%;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: #70767a;
  background: #fff
    repeating-linear-gradient(120deg, #f9f9f9, #f9f9f9 1px, #fff 2px, #fff 10px);
  --dimmed: rgba(255, 255, 255, 0.5);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.hide-scroll,
.hide-scroll > body {
  scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar,
.hide-scroll > body::-webkit-scrollbar {
  display: none;
}
.min-scroll,
.min-scroll > body {
  scrollbar-color: #888 transparent;
}
.min-scroll::-webkit-scrollbar,
.min-scroll > body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
.min-scroll::-webkit-scrollbar-thumb,
.min-scroll > body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:active {
  opacity: 0.8;
}

.error-icon::before {
  content: "\026a0";
  padding-right: 0.5em;
}

.base-btn:not(:disabled):hover,
.base-btn:not(:disabled):focus-visible {
  opacity: 0.8;
}
.base-btn:not(:disabled):active {
  opacity: 0.6;
}

.bg-mesh {
  background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 9px,
      #777 10px
    ),
    repeating-linear-gradient(0deg, transparent, transparent 9px, #777 10px);
}

.placeholder {
  min-height: 1rem;
  min-width: 1rem;
  animation: placeholder 1s linear infinite alternate;
}

@keyframes placeholder {
  from {
    background: rgba(3 3 3/0.1);
  }
  to {
    background: rgba(250 250 250/0.1);
  }
}
/*swiper widget*/

.swiper-colors {
  --swiper-theme-color: black;
  text-shadow: 0 1px 6px white;
}
.swiper-colors .swiper-button-next,
.swiper-colors .swiper-button-prev {
  cursor: e-resize;
  padding: 20px;
}
.swiper-colors .swiper-button-prev {
  cursor: w-resize;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(128 128 128/0.3);
  --swiper-theme-color: #666;
}
.swiper-colors .swiper-pagination-bullet {
  box-shadow: 0 0 1px 2px white;
}
#tw .swiper-button-disabled {
  opacity: 0;
}

/* animations */
@media screen {
  .slide-t {
    --tfm-x: 0;
    --tfm-y: 10vh;
  }
  .slide-b {
    --tfm-x: 0;
    --tfm-y: -10vh;
  }
  .slide-l {
    --tfm-x: 10vw;
    --tfm-y: 0;
  }
  .slide-r {
    --tfm-x: -10vw;
    --tfm-y: 0;
  }
  .slide-t,
  .slide-b,
  .slide-l,
  .slide-r,
  .zoom-in,
  .fade-in,
  .fade-out {
    transition: transform var(--anim-dur, 1s) var(--anim-delay, 0s) ease-out,
      opacity var(--anim-dur, 1s) var(--anim-delay, 0s) ease-out;
  }
  .fade-out {
    opacity: 0;
  }
  .hideanim {
    &.slide-t,
    &.slide-b,
    &.slide-l,
    &.slide-r,
    .slide-t,
    .slide-b,
    .slide-l,
    .slide-r {
      transform: translate(var(--tfm-x), var(--tfm-y));
      opacity: 0;
    }
    &.fade-in,
    .fade-in {
      opacity: 0;
    }
    &.fade-out,
    .fade-out {
      opacity: 1;
    }
    &.zoom-in,
    .zoom-in {
      transform: scale(0.9);
      opacity: 0;
    }
  }
}

.hover-rotate-y-360:hover {
  transition: transform 2s;
  transform: rotateY(360deg);
}
