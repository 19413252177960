.skewdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  user-select: none;
  backface-visibility: hidden;
  background-color: #888;
  background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 9px,
      #777 10px
    ),
    repeating-linear-gradient(0deg, transparent, transparent 9px, #777 10px);
}
